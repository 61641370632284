import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { $ } from 'protractor';
import { AudioPlayerService } from 'src/app/services/audio-player.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
})
export class PlayerComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;
  programa: any;
  currentTime: number;

  constructor(
    public audioPlayerService: AudioPlayerService
  ) { }

  ngOnInit() {
    console.log('programa-player', this.programa);
  }

  seekTo(event) {
    const skipTo = event.target.dataset.seek ? event.target.dataset.seek : event.target.value;
    this.audioPlayerService.seekTo(skipTo);
  }
}
