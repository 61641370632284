import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorHelpers } from './helpers/interceptor.helpers';
import { DatePipe } from '@angular/common';

import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { GoogleChartsModule } from 'angular-google-charts';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { PlayerComponent } from './components/player/player.component';
import { AudioPlayerService } from './services/audio-player.service';
import { IonicRatingComponentModule } from 'ionic-rating-component';

registerLocaleData(ptBr);

@NgModule({
    declarations: [AppComponent,PlayerComponent],
    imports: [
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        IonicStorageModule.forRoot(),
        Ng2GoogleChartsModule,
        GoogleChartsModule,
        IonicRatingComponentModule
    ],
    providers: [
        DatePipe,
        OneSignal,
        AudioPlayerService,
        {provide: LOCALE_ID,      useValue: 'pt-BR'    },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorHelpers, multi: true },
        File,
        AndroidPermissions,
        FileOpener,
        SocialSharing
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
