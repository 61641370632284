import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})

export class LoadingService {
    constructor(public loadingController: LoadingController) { }

    on() {
        this.loadingController.create({
            cssClass: 'loading',
            showBackdrop: false
        }).then((response) => {
            response.present();
        });
    }

    async off() {
        let topLoader = null;
        do{
            topLoader = await this.loadingController.getTop();
        }while(topLoader && (await topLoader.dismiss()));
    }

}