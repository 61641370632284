import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Usuario } from '../interfaces/usuario';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    storageKey = 'br.com.copacol.cooperado.web';
    storageUsuario = 'usuario';

    usuario: Usuario;

    constructor(private storage: Storage) {
        this.init();
    }

    async init() {
        await this.storage.create();
    }

    async logout() {
        this.storage.remove(this.storageKey + '-' + this.storageUsuario);
    }

    setUsuario(usuario: Usuario) {
        this.usuario = usuario;
        return this.storage.set(this.storageKey + '-' + this.storageUsuario, usuario);
    }

    async getUsuario() {
        if (!this.usuario) {
            await this.storage.get(this.storageKey + '-' + this.storageUsuario).then((usuarioStorage: Usuario) => {
                if (usuarioStorage) {
                    this.usuario = usuarioStorage;
                }
            });
        }
        return this.usuario;
    }

    async deleteUsuario() {
        this.usuario = null;
        this.storage.remove(this.storageKey + '-' + this.storageUsuario);
    }

    async get(key) {
        return this.storage.get(this.storageKey + '-' + key);
    }

    async set(key, data) {
        await this.storage.set(this.storageKey + '-' + key, data);
    }
}

