import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'inicial',
        loadChildren: () => import('./pages/inicial/inicial.module').then(m => m.InicialPageModule)
    },
    {
        path: 'onboarding',
        loadChildren: () => import('./pages/onboarding/onboarding.module').then(m => m.OnboardingPageModule)
    },
    {
        path: 'recuperar-senha',
        loadChildren: () => import('./pages/recuperar-senha/recuperar-senha.module').then(m => m.RecuperarSenhaPageModule)
    },
    {
        path: 'recuperar-senha-perguntas/:token',
        loadChildren: () => import('./pages/recuperar-senha-perguntas/recuperar-senha-perguntas.module').then(m => m.RecuperarSenhaPerguntasPageModule)
    },
    {
        path: 'meu-perfil',
        loadChildren: () => import('./pages/meu-perfil/meu-perfil.module').then(m => m.MeuPerfilPageModule)
    },
    {
        path: 'gerenciar-permissoes',
        loadChildren: () => import('./pages/gerenciar-permissoes/gerenciar-permissoes.module').then(m => m.GerenciarPermissoesPageModule)
    },
    {
        path: 'gerenciar-permissoes-add-primeiro',
        loadChildren: () => import('./pages/gerenciar-permissoes-add-primeiro/gerenciar-permissoes-add-primeiro.module').then(m => m.GerenciarPermissoesAddPrimeiroPageModule)
    },
    {
        path: 'gerenciar-permissoes-add-segundo',
        loadChildren: () => import('./pages/gerenciar-permissoes-add-segundo/gerenciar-permissoes-add-segundo.module').then(m => m.GerenciarPermissoesAddSegundoPageModule)
    },
    {
        path: 'notificacoes',
        loadChildren: () => import('./pages/notificacoes/notificacoes.module').then(m => m.NotificacoesPageModule)
    },
    {
        path: 'notificacoes-gerenciar',
        loadChildren: () => import('./pages/notificacoes-gerenciar/notificacoes-gerenciar.module').then(m => m.NotificacoesGerenciarPageModule)
    },
    {
        path: 'comunicacao',
        loadChildren: () => import('./pages/comunicacao/comunicacao.module').then(m => m.ComunicacaoPageModule)
    },
    {
        path: 'comunicacao-noticias',
        loadChildren: () => import('./pages/comunicacao-noticias/comunicacao-noticias.module').then(m => m.ComunicacaoNoticiasPageModule)
    },
    {
        path: 'comunicacao-radio',
        loadChildren: () => import('./pages/comunicacao-radio/comunicacao-radio.module').then(m => m.ComunicacaoRadioPageModule)
    },
    {
        path: 'comunicacao-noticia/:id',
        loadChildren: () => import('./pages/comunicacao-noticia/comunicacao-noticia.module').then(m => m.ComunicacaoNoticiaPageModule)
    },
    {
        path: 'panorama-climatico',
        loadChildren: () => import('./pages/panorama-climatico/panorama-climatico.module').then(m => m.PanoramaClimaticoPageModule)
    },
    {
        path: 'financeiro-contratos',
        loadChildren: () => import('./pages/financeiro-contratos/financeiro-contratos.module').then(m => m.FinanceiroContratosPageModule)
    },
    {
        path: 'financeiro-conta-capital',
        loadChildren: () => import('./pages/financeiro-conta-capital/financeiro-conta-capital.module').then(m => m.FinanceiroContaCapitalPageModule)
    },
    {
        path: 'financeiro-icms',
        loadChildren: () => import('./pages/financeiro-icms/financeiro-icms.module').then(m => m.FinanceiroIcmsPageModule)
    },
    {
        path: 'financeiro-imposto-de-renda',
        loadChildren: () => import('./pages/financeiro-imposto-de-renda/financeiro-imposto-de-renda.module').then(m => m.FinanceiroImpostoDeRendaPageModule)
    },
    {
        path: 'financeiro-notas-fiscais',
        loadChildren: () => import('./pages/financeiro-notas-fiscais/financeiro-notas-fiscais.module').then(m => m.FinanceiroNotasFiscaisPageModule)
    },
    {
        path: 'financeiro-valores-a-pagar',
        loadChildren: () => import('./pages/financeiro-valores-a-pagar/financeiro-valores-a-pagar.module').then(m => m.FinanceiroValoresAPagarPageModule)
    },
    {
        path: 'financeiro-valores-a-pagar-segundo',
        loadChildren: () => import('./pages/financeiro-valores-a-pagar-segundo/financeiro-valores-a-pagar-segundo.module').then(m => m.FinanceiroValoresAPagarSegundoPageModule)
    },
    {
        path: 'financeiro-cotacoes',
        loadChildren: () => import('./pages/financeiro-cotacoes/financeiro-cotacoes.module').then(m => m.FinanceiroCotacoesPageModule)
    },
    {
        path: 'financeiro-cotacoes/:tipo',
        loadChildren: () => import('./pages/financeiro-cotacoes/financeiro-cotacoes.module').then(m => m.FinanceiroCotacoesPageModule)
    },
    {
        path: 'agricultura-insumos/:modo',
        loadChildren: () => import('./pages/agricultura-insumos/agricultura-insumos.module').then(m => m.AgriculturaInsumosPageModule)
    },
    {
        path: 'agricultura-centro-de-pesquisa-agricola',
        loadChildren: () => import('./pages/agricultura-centro-de-pesquisa-agricola/agricultura-centro-de-pesquisa-agricola.module').then(m => m.AgriculturaCentroDePesquisaAgricolaPageModule)
    },
    {
        path: 'agricultura-fixacoes',
        loadChildren: () => import('./pages/agricultura-fixacoes/agricultura-fixacoes.module').then(m => m.AgriculturaFixacoesPageModule)
    },
    {
        path: 'agricultura-fixacoes-criar-solicitacao',
        loadChildren: () => import('./pages/agricultura-fixacoes-criar-solicitacao/agricultura-fixacoes-criar-solicitacao.module').then(m => m.AgriculturaFixacoesCriarSolicitacaoPageModule)
    },
    {
        path: 'agricultura-graos-e-contratos',
        loadChildren: () => import('./pages/agricultura-graos-e-contratos/agricultura-graos-e-contratos.module').then(m => m.AgriculturaGraosEContratosPageModule)
    },
    {
        path: 'agricultura-producao-fixar',
        loadChildren: () => import('./pages/agricultura-producao-fixar/agricultura-producao-fixar.module').then(m => m.AgriculturaProducaoFixarPageModule)
    },
    {
        path: 'agricultura-producao-entregue',
        loadChildren: () => import('./pages/agricultura-producao-entregue/agricultura-producao-entregue.module').then(m => m.AgriculturaProducaoEntreguePageModule)
    },
    {
        path: 'agricultura-producao-entregue-detalhes',
        loadChildren: () => import('./pages/agricultura-producao-entregue-detalhes/agricultura-producao-entregue-detalhes.module').then(m => m.AgriculturaProducaoEntregueDetalhesPageModule)
    },
    {
        path: 'agricultura-cereais',
        loadChildren: () => import('./pages/agricultura-cereais/agricultura-cereais.module').then(m => m.AgriculturaCereaisPageModule)
    },
    {
        path: 'agricultura-contratos-realizados',
        loadChildren: () => import('./pages/agricultura-contratos-realizados/agricultura-contratos-realizados.module').then(m => m.AgriculturaContratosRealizadosPageModule)
    },
    {
        path: 'agricultura-contratos-realizados-ver-contrato',
        loadChildren: () => import('./pages/agricultura-contratos-realizados-ver-contrato/agricultura-contratos-realizados-ver-contrato.module').then(m => m.AgriculturaContratosRealizadosVerContratoPageModule)
    },
    {
        path: 'avicultura-agendamentos',
        loadChildren: () => import('./pages/avicultura-agendamentos/avicultura-agendamentos.module').then(m => m.AviculturaAgendamentosPageModule)
    },
    {
        path: 'avicultura-solicitacao-de-racao',
        loadChildren: () => import('./pages/avicultura-solicitacao-de-racao/avicultura-solicitacao-de-racao.module').then(m => m.AviculturaSolicitacaoDeRacaoPageModule)
    },
    {
        path: 'avicultura-solicitacao-de-racao-solicitar',
        loadChildren: () => import('./pages/avicultura-solicitacao-de-racao-solicitar/avicultura-solicitacao-de-racao-solicitar.module').then(m => m.AviculturaSolicitacaoDeRacaoSolicitarPageModule)
    },
    {
        path: 'avicultura-fechamento-frangos',
        loadChildren: () => import('./pages/avicultura-fechamento-frangos/avicultura-fechamento-frangos.module').then(m => m.AviculturaFechamentoFrangosPageModule)
    },
    {
        path: 'suinocultura-fechamento',
        loadChildren: () => import('./pages/suinocultura-fechamento/suinocultura-fechamento.module').then(m => m.SuinoculturaFechamentoPageModule)
    },
    {
        path: 'piscicultura-fechamento',
        loadChildren: () => import('./pages/piscicultura-fechamento/piscicultura-fechamento.module').then(m => m.PisciculturaFechamentoPageModule)
    },
    {
        path: 'bovinocultura-fechamento',
        loadChildren: () => import('./pages/bovinocultura-fechamento/bovinocultura-fechamento.module').then(m => m.BovinoculturaFechamentoPageModule)
    },
    {
        path: 'avicultura-ordens-coleta',
        loadChildren: () => import('./pages/avicultura-ordens-coleta/avicultura-ordens-coleta.module').then(m => m.AviculturaOrdensColetaPageModule)
    },
    {
        path: 'suinocultura-agendamentos',
        loadChildren: () => import('./pages/suinocultura-agendamentos/suinocultura-agendamentos.module').then(m => m.SuinoculturaAgendamentosPageModule)
    },
    {
        path: 'suinocultura-fechamento-creche',
        loadChildren: () => import('./pages/suinocultura-fechamento-creche/suinocultura-fechamento-creche.module').then(m => m.SuinoculturaFechamentoCrechePageModule)
    },
    {
        path: 'aviso/:id',
        loadChildren: () => import('./pages/aviso/aviso.module').then(m => m.AvisoPageModule)
    },
    {
        path: 'financeiro-cheques',
        loadChildren: () => import('./pages/financeiro-cheques/financeiro-cheques.module').then(m => m.FinanceiroChequesPageModule)
    },
    {
        path: 'agricultura-opcoes-de-venda/:tipo',
        loadChildren: () => import('./pages/agricultura-opcoes-de-venda/agricultura-opcoes-de-venda.module').then(m => m.AgriculturaOpcoesDeVendaPageModule)
    },
    {
        path: 'agricultura-opcoes-de-venda-cadastro/:tipo',
        loadChildren: () => import('./pages/agricultura-opcoes-de-venda-cadastro/agricultura-opcoes-de-venda-cadastro.module').then(m => m.AgriculturaOpcoesDeVendaCadastroPageModule)
    },
    {
        path: 'avicultura-fechamento-ovos',
        loadChildren: () => import('./pages/avicultura-fechamento-ovos/avicultura-fechamento-ovos.module').then(m => m.AviculturaFechamentoOvosPageModule)
    },
    {
        path: 'avicultura-fechamento-ovos-detalhes',
        loadChildren: () => import('./pages/avicultura-fechamento-ovos-detalhes/avicultura-fechamento-ovos-detalhes.module').then(m => m.AviculturaFechamentoOvosDetalhesPageModule)
    },
    {
        path: 'solicitacao-racao/:area',
        loadChildren: () => import('./pages/solicitacao-racao/solicitacao-racao.module').then(m => m.SolicitacaoRacaoPageModule)
    },
    {
        path: 'solicitacao-racao-cadastro/:area',
        loadChildren: () => import('./pages/solicitacao-racao-cadastro/solicitacao-racao-cadastro.module').then(m => m.SolicitacaoRacaoCadastroPageModule)
    },
    {
        path: 'submenu',
        loadChildren: () => import('./pages/submenu/submenu.module').then(m => m.SubmenuPageModule)
    },
    {
        path: 'usuario-secundario-senha',
        loadChildren: () => import('./pages/usuario-secundario-senha/usuario-secundario-senha.module').then(m => m.UsuarioSecundarioSenhaPageModule)
    },
  {
    path: 'previsao-tempo',
    loadChildren: () => import('./pages/previsao-tempo/previsao-tempo.module').then( m => m.PrevisaoTempoPageModule)
  },
  {
    path: 'alterar-senha',
    loadChildren: () => import('./pages/alterar-senha/alterar-senha.module').then( m => m.AlterarSenhaPageModule)
  },
  {
    path: 'comunicacao-revistas',
    loadChildren: () => import('./pages/comunicacao-revistas/comunicacao-revistas.module').then( m => m.ComunicacaoRevistasPageModule)
  },
  {
    path: 'comunicacao-revista/:id',
    loadChildren: () => import('./pages/comunicacao-revista/comunicacao-revista.module').then( m => m.ComunicacaoRevistaPageModule)
  },
  {
    path: 'comunicacao-podcasts',
    loadChildren: () => import('./pages/comunicacao-podcasts/comunicacao-podcasts.module').then( m => m.ComunicacaoPodcastsPageModule)
  },
  {
    path: 'recuperar-senha-alterar/:token',
    loadChildren: () => import('./pages/recuperar-senha-alterar/recuperar-senha-alterar.module').then( m => m.RecuperarSenhaAlterarPageModule)
  },
  {
    path: 'panorama-climatico-ultimos-dias',
    loadChildren: () => import('./pages/panorama-climatico-ultimos-dias/panorama-climatico-ultimos-dias.module').then( m => m.PanoramaClimaticoUltimosDiasPageModule)
  },
  {
    path: 'panorama-climatico-ultimos-meses',
    loadChildren: () => import('./pages/panorama-climatico-ultimos-meses/panorama-climatico-ultimos-meses.module').then( m => m.PanoramaClimaticoUltimosMesesPageModule)
  },
  {
    path: 'financeiro-beneficios',
    loadChildren: () => import('./pages/financeiro-beneficios/financeiro-beneficios.module').then( m => m.FinanceiroBeneficiosPageModule)
  },
  {
    path: 'licencas-outorgas/:area',
    loadChildren: () => import('./pages/licencas-outorgas/licencas-outorgas.module').then( m => m.LicencasOutorgasPageModule)
  },
  {
    path: 'contratos-parceria/:area',
    loadChildren: () => import('./pages/contratos-parceria/contratos-parceria.module').then( m => m.ContratosParceriaPageModule)
  },
  {
    path: 'agricultura-visita-tecnica',
    loadChildren: () => import('./pages/agricultura-visita-tecnica/agricultura-visita-tecnica.module').then( m => m.AgriculturaVisitaTecnicaPageModule)
  },
  {
    path: 'visita/:id',
    loadChildren: () => import('./pages/visita/visita.module').then( m => m.VisitaPageModule)
  },
  {
    path: 'agricultura-producao-entregue-romaneio/:id',
    loadChildren: () => import('./pages/agricultura-producao-entregue-romaneio/agricultura-producao-entregue-romaneio.module').then( m => m.AgriculturaProducaoEntregueRomaneioPageModule)
  },
  {
    path: 'agricultura-retirada-insumos',
    loadChildren: () => import('./pages/agricultura-retirada-insumos/agricultura-retirada-insumos.module').then( m => m.AgriculturaRetiradaInsumosPageModule)
  },
  {
    path: 'agricultura-retirada-insumos-produtos',
    loadChildren: () => import('./pages/agricultura-retirada-insumos-produtos/agricultura-retirada-insumos-produtos.module').then( m => m.AgriculturaRetiradaInsumosProdutosPageModule)
  },
  {
    path: 'autorizacao-de-retirada',
    loadChildren: () => import('./pages/autorizacao-de-retirada/autorizacao-de-retirada.module').then( m => m.AutorizacaoDeRetiradaPageModule)
  },
  {
    path: 'autorizacao-de-retirada-add',
    loadChildren: () => import('./pages/autorizacao-de-retirada-add/autorizacao-de-retirada-add.module').then( m => m.AutorizacaoDeRetiradaAddPageModule)
  }


















];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
