import { Injectable, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { PlayerComponent } from '../components/player/player.component';
import { AppService } from 'src/app/services/app.service';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {

  isPlaying: boolean = false;
  currentTrack: any;
  seekValue = 0;
  currentTime: number;
  duration: number;
  currentTimeFormated: any;
  durationFormated: any;
  modal: any;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  async openPlayer(programa) {
    if (this.isPlaying) {
      this.currentTrack.currentTime = 0;
      this.seekValue = 0;
      if (this.isPlaying) {
        this.setPlayPause();
        this.modal.dismiss();
      }
    }
    this.currentTime = 0;
    this.modal = await this.modalCtrl.create({
      component: PlayerComponent,
      cssClass: 'player-modal',
      backdropDismiss: false,
      initialBreakpoint: 0.15,
      breakpoints: [0.30, 0.15],
      backdropBreakpoint: 0.5,
      componentProps: {
        modal: this,
        programa: programa
      }
    });
    await this.modal.present();
    this.setCurrentTrack(new Audio(programa.arquivo));
    this.togglePlayer();
  }

  setPlayPause() {
    if (this.isPlaying) {
      this.pause();
      this.isPlaying = !this.isPlaying;
      return;
    }

    this.play();
    this.isPlaying = !this.isPlaying;
  }

  getCurrentTrack(): any {
    return this.currentTrack;
  }

  setCurrentTrack(value: any) {
    this.currentTrack = value;
  }

  getTrackDuration() {
    if (this.currentTrack)
      return this.currentTrack.duration;
  }

  seekTo(value: number) {
    this.currentTrack.currentTime = value;
  }

  play() {
    this.currentTrack.play();
  }

  pause() {
    this.currentTrack.pause();
  }

  toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { h: hours, m: minutes, s: seconds };
  }

  togglePlayer() {
    this.setPlayPause();
    if (this.isPlaying) {
      this.currentTrack.addEventListener('timeupdate', (event) => {
        this.currentTime = Math.floor(this.currentTrack.currentTime);
        this.duration = Math.floor(this.currentTrack.duration);
        this.durationFormated = this.toHoursAndMinutes(this.duration);
        this.currentTimeFormated = this.toHoursAndMinutes(this.currentTime);
        this.seekValue = this.currentTime;
        if (this.currentTime == this.duration) {
          this.setPlayPause();
          this.currentTrack.currentTime = 0;
          this.seekValue = 0;
        }
      }, false);
      return;
    }

    this.currentTrack.pause();
  }

  closePlayer() {
    this.modalCtrl.dismiss();
    this.currentTrack.currentTime = 0;
    this.seekValue = 0;
    if (this.isPlaying) {
      this.setPlayPause();
    }
  }
}